export const extractVideoId = (idOrUrl: string) => {
  if (!idOrUrl) return idOrUrl;
  const lastAfterSlash = idOrUrl.split("/").reverse()[0];
  return lastAfterSlash.split("v=").reverse()[0].split("&")[0];
};

export const sanitizeYoutubeUrl = (idOrUrl: string) =>
  `https://www.youtube.com/watch?v=${extractVideoId(idOrUrl)}`;

export const getYoutubeThumbnails = (youtubeId: string) => ({
  thumbUrl: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
  originalUrl: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
});
