import React from "react";
import Rectangle from "react-rectangle";

import Player from "react-player/youtube";
import styled from "styled-components";
import { sanitizeYoutubeUrl } from "../utils/extractVideoId";
const Base = styled.div``;

interface AspectRatio {
  w: number;
  h: number;
}

export interface YoutubeVideoProps {
  style?: React.CSSProperties;
  className?: string;
  videoId: string;
  aspectRatio?: AspectRatio;
  mute?: boolean;
  autoplay?: boolean;
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({
  style,
  className,
  videoId,
  aspectRatio,
  mute,
  autoplay,
}) => (
  <Base style={style} className={className}>
    <Rectangle
      aspectRatio={aspectRatio ? aspectRatio.w / aspectRatio.h : 4 / 3}
    >
      <Player
        url={sanitizeYoutubeUrl(videoId)}
        width="100%"
        height="100%"
        muted={mute}
        playing={autoplay}
        controls={true}
      />
    </Rectangle>
  </Base>
);

export default React.memo(YoutubeVideo);
